import React from "react";

const Content = () => {
    return (
        <>
            <main id="body-content">

                {/* <section className="bg-white wide-tb-100">
                    <div className="container pos-rel">
                        <div className="row">
                            <div className="col-md-12 col-lg-6">
                                <p><img src="images/years_img.png" alt="" className="mb-5" /></p>
                                <p>La Société LOG 2 TRANS est une filiale de <b className="text-secondary">KIMORHAR INVESTMENT GROUP</b>, spécialisée en : </p>
                                <div className="img-icon mt-4 d-flex align-items-center">
                                    <img src="images/team/team-1.jpg" />
                                    <h3>
                                        John Morise
                                        <span>Founder & CEO</span>
                                    </h3>
                                </div>
                            </div>

                            <div className="w-100 spacer-50 d-none d-md-block d-lg-none d-sm-none"></div>
                            <div className="col-md-12 col-lg-6">

                                <div className="row">
                                    <div className="col-12 col-md-6">
                                        <div className="icon-box-6">
                                            <i className="icofont-box"></i>
                                            <h3 className="h3-xs txt-blue">Packaging and Storage</h3>
                                            <p>Praesent pellentesque diam vitae nibh aliquam faucibus.</p>
                                            <a href="#" className="btn-arrow bg-primary"><i className="icofont-swoosh-right"></i></a>
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-6">
                                        <div className="icon-box-6">
                                            <i className="icofont-safety"></i>
                                            <h3 className="h3-xs txt-blue">Safety & Quality</h3>
                                            <p>Praesent pellentesque diam vitae nibh aliquam faucibus.</p>
                                            <a href="#" className="btn-arrow bg-primary"><i className="icofont-swoosh-right"></i></a>
                                        </div>
                                    </div>

                                    <div className="w-100 spacer-50 d-none d-lg-none d-md-block d-lg-block d-sm-none"></div>

                                    <div className="col-12 col-md-6">
                                        <div className="icon-box-6">
                                            <i className="icofont-tree-alt"></i>
                                            <h3 className="h3-xs txt-blue">Care for Environment</h3>
                                            <p>Praesent pellentesque diam vitae nibh aliquam faucibus.</p>
                                            <a href="#" className="btn-arrow bg-primary"><i className="icofont-swoosh-right"></i></a>
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-6">
                                        <div className="icon-box-6">
                                            <i className="icofont-delivery-time"></i>
                                            <h3 className="h3-xs txt-blue">Delivery On Time</h3>
                                            <p>Praesent pellentesque diam vitae nibh aliquam faucibus.</p>
                                            <a href="#" className="btn-arrow bg-primary"><i className="icofont-swoosh-right"></i></a>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>
                </section> */}

                <section className="bg-light-gray wide-tb-100 bg-wave">
                    <div className="container pos-rel">
                        <div className="row">
                            <div className="col-md-6">
                                <img src="images/about_img_2.jpg" className="bordered-img" alt="" />
                            </div>

                            <div className="col-md-6 ml-auto why-choose wow fadeInRight d-flex flex-column justify-content-center" data-wow-duration="0" data-wow-delay="0.6s">
                                <h1 className="heading-main text-left wow fadeInDown mb-3" data-wow-duration="0" data-wow-delay="0s">
                                    <span>A Propos de Nous</span>
                                    Bienvenue à Log2Trans
                                </h1>

                                <p>La Société LOG 2 TRANS est une filiale de <span className="font-weight-bold text-secondary"> KIMORHAR INVESTMENT GROUP</span>, spécialisée en </p>

                                <div className="skillbar-wrap mt-2">
                                    <div className="clearfix">
                                        Commission en Douane
                                    </div>
                                    <div className="skillbar" data-percent="75%">
                                        <div className="skillbar-percent bg-primary">75%</div>
                                        <div className="skillbar-bar"></div>
                                    </div>
                                </div>

                                <div className="skillbar-wrap">
                                    <div className="clearfix">
                                        Logistique industrielle et pétrolière
                                    </div>
                                    <div className="skillbar" data-percent="50%">
                                        <div className="skillbar-percent bg-primary">50%</div>
                                        <div className="skillbar-bar"></div>
                                    </div>
                                </div>

                                <div className="skillbar-wrap">
                                    <div className="clearfix">
                                        Transport et Manutention
                                    </div>
                                    <div className="skillbar" data-percent="95%">
                                        <div className="skillbar-percent bg-primary">95%</div>
                                        <div className="skillbar-bar"></div>
                                    </div>
                                </div>
                                <div className="skillbar-wrap">
                                    <div className="clearfix">
                                        Intermédiation dans le commerce international
                                    </div>
                                    <div className="skillbar" data-percent="60%">
                                        <div className="skillbar-percent bg-primary">60%</div>
                                        <div className="skillbar-bar"></div>
                                    </div>
                                </div>
                                <div className="skillbar-wrap">
                                    <div className="clearfix">
                                        Conseil en achat/vente à l’international
                                    </div>
                                    <div className="skillbar" data-percent="95%">
                                        <div className="skillbar-percent bg-primary">95%</div>
                                        <div className="skillbar-bar"></div>
                                    </div>
                                </div>
                                <div className="skillbar-wrap mb-2">
                                    <div className="clearfix">
                                        Conseil en procédures douanières.
                                    </div>
                                    <div className="skillbar" data-percent="95%">
                                        <div className="skillbar-percent bg-primary">95%</div>
                                        <div className="skillbar-bar"></div>
                                    </div>
                                </div>

                                <p>Notre force est de pouvoir s'associer, si besoin, aux compétences des sociétés partenaires spécialisées.</p>
                            </div>
                        </div>

                    </div>
                </section>

                <section className="bg-white wide-tb-100">
                    <div className="container pos-rel">
                        <div className="row">
                            <div className="col-sm-12">
                                <h1 className="heading-main wow fadeInDown mb-3" data-wow-duration="0" data-wow-delay="0s">
                                    <span>Nos prestations</span>
                                    Pourquoi nous choisir ?
                                </h1>
                                <div className="w-75 mx-auto mb-5 text-center">
                                    <p className="mb-0">Notre savoir-faire et nos compétences s'expriment pleinement dans la mise en œuvre de projets industriels internationaux. L'ensemble de nos ressources et de nos métiers est mis à votre disposition et entièrement mobilisé pour répondre à tous les projets qui nous seront confiés. Dans le cadre de notre futur collaboration, ci-dessous quelques prestations spécifiques :</p>
                                </div>
                            </div>

                            <div className="col-12 col-lg-4 d-flex flex-column justify-content-center">
                                <div className="icon-box-2">
                                    <div className="media">
                                        <div className="service-icon">
                                            <i className="icofont-check-circled"></i>
                                        </div>
                                        <div className="service-inner-content media-body">
                                            <h4 className="h4-md">Gain de temps</h4>
                                            <p>L’optimisation des procédures douanières.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="icon-box-2">
                                    <div className="media">
                                        <div className="service-icon">
                                            <i className="icofont-check-circled"></i>
                                        </div>
                                        <div className="service-inner-content media-body">
                                            <h4 className="h4-md">Coûts</h4>
                                            <p>La maîtrise de vos coûts de revient.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="icon-box-2">
                                    <div className="media">
                                        <div className="service-icon">
                                            <i className="icofont-check-circled"></i>
                                        </div>
                                        <div className="service-inner-content media-body">
                                            <h4 className="h4-md">Tracking</h4>
                                            <p>La mise à disposition d’informations en temps réel relatives à l’évolution de vos dossiers et la position de vos marchandises.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-lg-4 d-flex flex-column justify-content-center">
                                <img src="images/img-2.jpg" alt="" />
                            </div>

                            <div className="col-12 col-lg-4 d-flex flex-column justify-content-center">
                                <div className="icon-box-2">
                                    <div className="media">
                                        <div className="service-icon">
                                            <i className="icofont-check-circled"></i>
                                        </div>
                                        <div className="service-inner-content media-body">
                                            <h4 className="h4-md">Relationnel</h4>
                                            <p>La négociation des facilités douanières.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="icon-box-2">
                                    <div className="media">
                                        <div className="service-icon">
                                            <i className="icofont-check-circled"></i>
                                        </div>
                                        <div className="service-inner-content media-body">
                                            <h4 className="h4-md">Transport</h4>
                                            <p>Gestion du transfert des marchandises de site à l’autre.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="icon-box-2">
                                    <div className="media">
                                        <div className="service-icon">
                                            <i className="icofont-check-circled"></i>
                                        </div>
                                        <div className="service-inner-content media-body">
                                            <h4 className="h4-md">Garantie</h4>
                                            <p>La maîtrise des risques en commerce international.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>

                <section className="bg-light-gray wide-tb-100 bg-wave">
                    <div className="container pos-rel">
                        <div className="row">
                            {/* <div className="img-business-man">
                                <img src="images/business-man.png" alt="" />
                            </div> */}
                            <div className="col-sm-12 wow fadeInDown" data-wow-duration="0" data-wow-delay="0s">
                                <h1 className="heading-main">
                                    <span>Notre organisation</span>
                                    Ce qui nous rends spécial
                                </h1>
                            </div>
                            <div className="col-md-12 ml-auto why-choose wow fadeInRight" data-wow-duration="0" data-wow-delay="0.6s">
                                <div className="row">

                                    <div className="col-12 col-lg-6">
                                        <div className="icon-box-2">
                                            <div className="media">
                                                <div className="service-icon">
                                                    <i className="icofont-history"></i>
                                                </div>
                                                <div className="service-inner-content media-body">
                                                    <h4 className="h4-md">Nos relations</h4>
                                                    <ul className="list-unstyled theme-dark mb-0">
                                                        <li><i className="icofont-dotted-right"></i>Notre réseau de correspondants</li>
                                                        <li><i className="icofont-dotted-right"></i>Notre parfaite maîtrise du cadre juridique et institutionnel en matière de Douane, Transport, Logistique et Transit</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 col-lg-6">
                                        <div className="icon-box-2">
                                            <div className="media">
                                                <div className="service-icon">
                                                    <i className="icofont-live-support"></i>
                                                </div>
                                                <div className="service-inner-content media-body">
                                                    <h4 className="h4-md">Notre environnement de travail</h4>
                                                    <ul className="list-unstyled theme-dark mb-0">
                                                        <li><i className="icofont-dotted-right"></i>Cadre de travail confortable</li>
                                                        <li><i className="icofont-dotted-right"></i>Des équipements technologiques de pointe</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="w-100 spacer-60 d-none d-md-none d-lg-block d-sm-none"></div>

                                    <div className="col-12 col-lg-6">
                                        <div className="icon-box-2">
                                            <div className="media">
                                                <div className="service-icon">
                                                    <i className="icofont-id"></i>
                                                </div>
                                                <div className="service-inner-content media-body">
                                                    <h4 className="h4-md">Notre équipe</h4>
                                                    <ul className="list-unstyled theme-dark mb-0">
                                                        <li><i className="icofont-dotted-right"></i>Des professionnels pétris d’expérience</li>
                                                        <li><i className="icofont-dotted-right"></i> Personnel jeune, qualifié et dynamique</li>
                                                        <li><i className="icofont-dotted-right"></i>Personnel aux compétences pluridisciplinaires et complémentaires (Management des Organisations, Droit, Gestion des Entreprises, Informatiques, Commerce international, Banque et finances…).</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-6">
                                        <div className="icon-box-2">
                                            <div className="media">
                                                <div className="service-icon">
                                                    <i className="icofont-briefcase-1"></i>
                                                </div>
                                                <div className="service-inner-content media-body">
                                                    <h4 className="h4-md">Notre système d'information</h4>
                                                    <ul className="list-unstyled theme-dark mb-0">
                                                        <li><i className="icofont-dotted-right"></i>Une connexion au réseau Internet facilitant la transmission des informations </li>
                                                        <li><i className="icofont-dotted-right"></i>Une plate-forme dédiée aux opérations</li>
                                                        <li><i className="icofont-dotted-right"></i>Acquisition régulière des textes et arrêtés régulant les activités commerciales</li>
                                                        <li><i className="icofont-dotted-right"></i>Une bibliothèque régulièrement enrichie de nouveaux ouvrages</li>
                                                        <li><i className="icofont-dotted-right"></i>Le développement des bases de données informatiques</li>
                                                        <li><i className="icofont-dotted-right"></i>Un futur site internet interactif</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>

                <section className="wide-tb-100 bg-scroll counter-bg pos-rel">
                    <div className="bg-overlay blue opacity-50"></div>
                    <div className="container">
                        <div className="row">
                            <div className="col counter-style-1 col-6 col-lg-3 col-sm-6 wow fadeInLeft" data-wow-duration="0" data-wow-delay="0s">
                                <p><i className="icofont-google-map"></i></p>
                                <span className="counter">15</span>
                                <div>
                                    Sites
                                </div>
                            </div>

                            <div className="col counter-style-1 col-6 col-lg-3 col-sm-6 wow fadeInLeft" data-wow-duration="0" data-wow-delay="0s">
                                <p><i className="icofont-globe"></i></p>
                                <span className="counter">110</span>
                                <span>+</span>
                                <div>
                                    Clients
                                </div>
                            </div>

                            <div className="w-100 d-none d-sm-block d-lg-none spacer-60"></div>

                            <div className="col counter-style-1 col-6 col-lg-3 col-sm-6 wow fadeInRight" data-wow-duration="0" data-wow-delay="0">
                                <p><i className="icofont-vehicle-delivery-van"></i></p>
                                <span className="counter">240</span>
                                <span>+</span>
                                <div>
                                    Véhicules
                                </div>
                            </div>

                            <div className="col counter-style-1 col-6 col-lg-3 col-sm-6 wow fadeInRight" data-wow-duration="0" data-wow-delay="0s">
                                <p><i className="icofont-umbrella-alt"></i></p>
                                <span className="counter">2340</span>
                                <div>
                                    Tonnes Transportées
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="bg-sky-blue wide-tb-100">
                    <div className="container pos-rel">
                        <div className="row">
                            <div className="img-business-man">
                                <img src="images/courier-man.png" alt="" />
                            </div>
                            <div className="col-sm-12 wow fadeInDown" data-wow-duration="0" data-wow-delay="0s">
                                <h1 className="heading-main">
                                    <span>Notre expertise</span>
                                    Vous gagnerez
                                </h1>
                            </div>
                            <div className="col-md-6 ml-auto">
                                <div className="row">
                                    <div className="col-12 wow fadeInUp" data-wow-duration="0" data-wow-delay="0s">
                                        <div className="icon-box-3 mb-5 bg-sky-blue">
                                            <div className="media">
                                                <div className="service-icon mr-5">
                                                    <i className="icofont-box"></i>
                                                </div>
                                                <div className="service-inner-content media-body">
                                                    <h4 className="h4-md">En temps</h4>
                                                    <p>Vous réduirez le temps consacré au suivi de procédures de dédouanement de vos marchandises.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 wow fadeInUp" data-wow-duration="0" data-wow-delay="0.2s">
                                        <div className="icon-box-3 mb-5 bg-sky-blue">
                                            <div className="media">
                                                <div className="service-icon mr-5">
                                                    <i className="icofont-shield"></i>
                                                </div>
                                                <div className="service-inner-content media-body">
                                                    <h4 className="h4-md">En tranquillité</h4>
                                                    <p>Vous serez désormais exempts des tracasseries douanières et de la lourdeur des procédures administratives dans les différentes administrations y relatives.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 wow fadeInUp" data-wow-duration="0" data-wow-delay="0.4s">
                                        <div className="icon-box-3 bg-sky-blue">
                                            <div className="media">
                                                <div className="service-icon mr-5">
                                                    <i className="icofont-tree-alt"></i>
                                                </div>
                                                <div className="service-inner-content media-body">
                                                    <h4 className="h4-md">En fiabilité</h4>
                                                    <p>Le grand apport des Nouvelles Technologies de l’Informations et de la Communication dans notre mode de fonctionnement garantira la rapidité et la fiabilité de vos opérations ;La mise à jour de notre base de données des textes et règlements nationaux et internationaux garantira l’actualisation de notre expertise sur le plan juridique.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>

                {/* <section className="bg-sky-blue wide-tb-100 pb-5 why-choose">
                    <div className="container pos-rel">
                        <div className="contact-map-bg">
                            <img src="images/map-bg.png" alt="" />
                        </div>
                        <div className="row piecharts" id="pie-charts">
                            <div className="col-sm-12 wow fadeInDown" data-wow-duration="0" data-wow-delay="0s">
                                <h1 className="heading-main">
                                    <span>Looking for more?</span>
                                    Watch Our Intro Video
                                </h1>
                            </div>
                            <div className="col-md-6 offset-md-3">
                                <div className="row">
                                    <div className="col-lg-4 col-md-4 col-sm-4 col-6">
                                        <span className="chart" data-percent="90">
                                            <span className="percent"></span>
                                        </span>
                                        <div className="skill-name">Road Transport</div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-4 col-6">
                                        <span className="chart" data-percent="90">
                                            <span className="percent"></span>
                                        </span>
                                        <div className="skill-name">Logistics</div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                                        <span className="chart" data-percent="95">
                                            <span className="percent"></span>
                                        </span>
                                        <div className="skill-name">Truck Rental</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
                {/* <section className="wide-tb-60 p-0 bg-sky-blue">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1 wow pulse animated" data-wow-duration="0" data-wow-delay="0.7s">
                                <div className="bg-fixed pos-rel video-popup">
                                    <div className="bg-overlay black opacity-50"></div>
                                    <div className="zindex-fixed pos-rel">
                                        <a href="#" className="play-video"><i className="icofont-play icofont-4x"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}

                <section className="wide-tb-100 mb-spacer-md">
                    <div className="container wide-tb-100 pb-0">
                        <div className="row">
                            <div className="col-sm-12 wow fadeInDown" data-wow-duration="0" data-wow-delay="0s">
                                <h1 className="heading-main">
                                    <span>Ce que nos</span>
                                    Clients disent
                                </h1>
                            </div>
                            <div className="col-sm-12">
                                <div className="owl-carousel owl-theme" id="home-client-testimonials">

                                    <div className="item">
                                        <div className="client-testimonial bg-wave">
                                            <div className="media">
                                                <div className="client-testimonial-icon rounded-circle bg-primary">
                                                    <img src="images/team_1.jpg" alt="" />
                                                </div>
                                                <div className="client-inner-content media-body">
                                                    <p>Un service très excellent, je recommande fortement</p>
                                                    <footer className="blockquote-footer"><cite title="Source Title">John Gerry  Design Hunt</cite></footer>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="item">
                                        <div className="client-testimonial bg-wave">
                                            <div className="media">
                                                <div className="client-testimonial-icon rounded-circle bg-primary">
                                                    <img src="images/team_2.jpg" alt="" />
                                                </div>
                                                <div className="client-inner-content media-body">
                                                    <p>Un service très excellent, je recommande fortement </p>
                                                    <footer className="blockquote-footer"><cite title="Source Title">John Gerry  Design Hunt</cite></footer>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="item">
                                        <div className="client-testimonial bg-wave">
                                            <div className="media">
                                                <div className="client-testimonial-icon rounded-circle bg-primary">
                                                    <img src="images/team_3.jpg" alt="" />
                                                </div>
                                                <div className="client-inner-content media-body">
                                                    <p>Un service très excellent, je recommande fortement</p>
                                                    <footer className="blockquote-footer"><cite title="Source Title">John Gerry  Design Hunt</cite></footer>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="wide-tb-80 bg-primary callout-style-1 wow fadeInUp" data-wow-duration="0" data-wow-delay="0s">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-4 col-md-12 mb-0">
                                <h4 className="h4-xl">Voulez vous travailler avec Log2trans?</h4>
                            </div>
                            <div className="col">
                                <div className="center-text">
                                    Nous ne nous contentons pas de gérer les fournisseurs, nous les micro-gérons. Nous avons une 
                                    approche consultative et personnalisée
                                </div>
                            </div>
                            <div className="col-sm-auto">
                                <a href="/contact" className="btn-theme bg-white light">Nous contacter <i className="icofont-rounded-right"></i></a>
                            </div>
                        </div>
                    </div>
                </section>
                
                <section className="wide-tb-100 bg-fixed clients-bg pos-rel">
                    <div className="bg-overlay blue opacity-80"></div>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 wow fadeInDown" data-wow-duration="0" data-wow-delay="0s">
                                <h1 className="heading-main">
                                    <span>Quelques de nos</span>
                                    Partenaires
                                </h1>
                            </div>

                            <div className="col-sm-12 wow fadeInUp" data-wow-duration="0" data-wow-delay="0.2s">
                                <div className="owl-carousel owl-theme" id="home-clients">
                                    <div className="item">
                                        <img src="images/clients/africacontainershipping.jpg" alt="" />
                                    </div>
                                    <div className="item">
                                        <img src="images/clients/asecna.png" alt="" />
                                    </div>
                                    <div className="item">
                                        <img src="images/clients/btc.png" alt="" />
                                    </div>
                                    <div className="item">
                                        <img src="images/clients/gulfcam.png" alt="" />
                                    </div>
                                    <div className="item">
                                        <img src="images/clients/moleculesgas.png" alt="" />
                                    </div>
                                    <div className="item">
                                        <img src="images/clients/novaplast.png" alt="" />
                                    </div>
                                    <div className="item">
                                        <img src="images/clients/parlym.png" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="map-bg">
                    <div className="contact-details row d-flex">
                        <div className="col">
                            <h4> AKWA, Douala, Cameroun</h4>
                            <p><i className="icofont-phone"></i> +237 677 33 71 69</p>
                            <div className="text-nowrap"><i className="icofont-email"></i> <a href="/">support@log2trans.com</a></div>
                        </div>
                    </div>
                    <div id="map-holder" className="pos-rel">
                        <div id="map_extended">
                            <p>This will be replaced with the Google Map.</p>
                        </div>
                    </div>
                </section>

            </main>
        </>
    );
};
export default Content;
